// components/Services.js
import React, { useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { Canvas } from '@react-three/fiber';
import { OrbitControls, Box } from '@react-three/drei';
import { BentoGrid, BentoGridItem } from './ui/BentoGrid';

const servicesData = [
    {
        title: 'Custom VR Experiences',
        description: 'We create tailored VR experiences that transport users to immersive virtual worlds, enhancing engagement and providing unforgettable experiences.',
        icon: '🎮',
        size: 'large',
        type: 'threejs'
    },
    {
        title: 'Augmented Reality Solutions',
        description: 'Our AR solutions blend the digital and physical worlds, offering innovative ways to interact with your environment and enrich real-life experiences.',
        icon: '🌐',
        size: 'small',
        type: 'image',
        media: ''
    },
    {
        title: 'Interactive Installations',
        description: 'We design and develop interactive installations that captivate audiences, turning any space into an engaging and interactive environment.',
        icon: '🖼️',
        size: 'small',
        type: 'video',
        media: 'https://www.w3schools.com/html/mov_bbb.mp4'
    },
    {
        title: '3D Modeling and Animation',
        description: 'Our team excels in 3D modeling and animation, creating stunning visuals and lifelike animations for various applications and industries.',
        icon: '🎨',
        size: 'medium',
        type: 'image',
        media: ''
    },
    {
        title: 'Consulting and Training',
        description: 'We provide expert consulting and training services to help you navigate the XR landscape, ensuring your team is equipped with the latest knowledge and skills.',
        icon: '📚',
        size: 'medium',
        type: 'video',
        media: 'https://www.w3schools.com/html/mov_bbb.mp4'
    },
    {
        title: 'Enterprise VR Platforms',
        description: 'Our enterprise VR platforms are designed to enhance business operations, providing immersive training, virtual meetings, and collaborative environments.',
        icon: '🏢',
        size: 'large',
        type: 'threejs'
    },
    {
        title: 'AR Marketing Campaigns',
        description: 'We create engaging AR marketing campaigns that captivate audiences and drive brand awareness through interactive and immersive experiences.',
        icon: '📈',
        size: 'small',
        type: 'image',
        media: ''
    },
    {
        title: 'Immersive Training Programs',
        description: 'Our immersive training programs leverage VR and AR to provide realistic simulations, improving learning outcomes and retention rates.',
        icon: '🎓',
        size: 'small',
        type: 'video',
        media: 'https://www.w3schools.com/html/mov_bbb.mp4'
    },
    {
        title: 'Custom Software Development',
        description: 'Our team specializes in custom software development, creating tailored solutions that meet the unique needs of your business and enhance your operations.',
        icon: '💻',
        size: 'medium',
        type: 'video',
        media: 'https://www.w3schools.com/html/mov_bbb.mp4'
    },
];

const Loader = () => (
    <div className="loader"></div>
);

const Services = () => {
    const [selectedService, setSelectedService] = useState(null);

    const handleServiceClick = (service) => {
        setSelectedService(service);
    };

    const handleClose = () => {
        setSelectedService(null);
    };

    return (
        <div className="services-container min-h-screen flex flex-col items-center justify-center text-center p-8">
            <motion.h1
                className="text-4xl font-bold mb-4"
                initial={{ opacity: 0, y: -50 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.6 }}
            >
                Our Services
            </motion.h1>
            <motion.p
                className="text-lg mb-8"
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ duration: 0.6, delay: 0.3 }}
            >
                We offer a wide range of services to help you leverage the latest in XR technology. From VR development to AR applications, our team is equipped to handle projects of any scale and complexity.
            </motion.p>
            <BentoGrid>
                {servicesData.map((service, index) => (
                    <BentoGridItem
                        key={index}
                        className={service.size}
                        title={service.title}
                        description={service.description}
                        icon={<div className="service-icon text-5xl">{service.icon}</div>}
                        onClick={() => handleServiceClick(service)}
                    />
                ))}
            </BentoGrid>
            <AnimatePresence>
                {selectedService && (
                    <motion.div
                        className="overlay"
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        exit={{ opacity: 0 }}
                        onClick={handleClose}
                    >
                        <motion.div
                            className="detailed-view"
                            initial={{ scale: 0.8, opacity: 0 }}
                            animate={{ scale: 1, opacity: 1 }}
                            exit={{ scale: 0.8, opacity: 0 }}
                            onClick={(e) => e.stopPropagation()}
                        >
                            <button className="close-button" onClick={handleClose}>✖</button>
                            <h2 className="text-2xl font-bold mb-4">{selectedService.title}</h2>
                            <p className="text-lg mb-4">{selectedService.description}</p>
                            {selectedService.type === 'image' && (
                                selectedService.media ?
                                    <img src={selectedService.media} alt={selectedService.title} className="w-full rounded-lg" /> :
                                    <div className="w-full flex justify-center items-center"><Loader /></div>
                            )}
                            {selectedService.type === 'video' && (
                                selectedService.media ?
                                    <video controls className="w-full rounded-lg">
                                        <source src={selectedService.media} type="video/mp4" />
                                        Your browser does not support the video tag.
                                    </video> :
                                    <div className="w-full flex justify-center items-center"><Loader /></div>
                            )}
                            {selectedService.type === 'threejs' && (
                                <Canvas style={{ height: '300px', borderRadius: '10px' }}>
                                    <ambientLight />
                                    <OrbitControls />
                                    <Box args={[1, 1, 1]}>
                                        <meshStandardMaterial attach="material" color="orange" />
                                    </Box>
                                </Canvas>
                            )}
                        </motion.div>
                    </motion.div>
                )}
            </AnimatePresence>
        </div>
    );
};

export default Services;
