import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import logo from './assets/logo.png';
import './App.css';
import FullPageCanvas from './components/FullPageCanvas';
import AboutUs from './components/AboutUs';
import Services from './components/Services';
import Solutions from './components/Solutions';
import ContactUs from './components/ContactUs';
import { FlipWords } from './components/ui/FlipWords';
import useScrollBackground from './hooks/useScrollBackground';
import { useStore } from './store/useStore';
import Loader from './components/Loader';

const phrases = [
  "immersive experiences",
  "innovative solutions",
  "cutting-edge technology",
  "unique interactions"
];

const prefixPhrases = [
  "We create",
  "We develop",
  "We design"
];

const App = () => {
  const { blurBackground } = useStore();
  const [currentPrefixIndex, setCurrentPrefixIndex] = useState(0);
  const backgroundImage = useScrollBackground([
    'bg1.jpg',
    'bg2.jpg',
    'bg3.jpg',
    'bg4.jpg'
  ]);

  const [scrollY, setScrollY] = useState(0);
  const [animationActive, setAnimationActive] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const handleScroll = () => setScrollY(window.scrollY);
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  useEffect(() => {
    const prefixInterval = setInterval(() => {
      setCurrentPrefixIndex((prevIndex) => (prevIndex + 1) % prefixPhrases.length);
    }, 8000);
    return () => clearInterval(prefixInterval);
  }, []);

  const handleLogoClick = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  const handleSceneClick = () => {
    setAnimationActive(!animationActive);
  };

  const handleLoadingComplete = () => {
    setLoading(false);
  };

  return (
      <Router>
        {loading && <Loader onLoadingComplete={handleLoadingComplete} />}
        <div
            className={`min-h-screen bg-white flex flex-col items-center ${blurBackground ? 'blur-background' : ''}`}
            style={{ backgroundImage: `url(${backgroundImage})`, backgroundSize: 'cover', backgroundAttachment: 'fixed' }}
        >
          <header className="mt-8">
            <img src={logo} alt="ATSAE Logo" className="h-20 cursor-pointer" onClick={handleLogoClick} />
          </header>
          <nav className="glass-navbar mt-4 mb-12">
            <ul className="flex space-x-8 px-8 py-2 rounded-md">
              <li><Link to="/" className="text-gray-700 hover:text-blue-500">Home</Link></li>
              <li><Link to="/about" className="text-gray-700 hover:text-blue-500">About Us</Link></li>
              <li><Link to="/services" className="text-gray-700 hover:text-blue-500">Services</Link></li>
              <li><Link to="/solutions" className="text-gray-700 hover:text-blue-500">Solutions</Link></li>
              <li><Link to="/contact" className="text-gray-700 hover:text-blue-500">Contact Us</Link></li>
            </ul>
          </nav>
          <main className="flex flex-col items-center text-center mt-8 md:mt-16">
            <Routes>
              <Route path="/" element={<HomePage phrases={phrases} prefixPhrases={prefixPhrases} currentPrefixIndex={currentPrefixIndex} animationActive={animationActive} handleSceneClick={handleSceneClick} />} />
              <Route path="/about" element={<AboutUs />} />
              <Route path="/services" element={<Services />} />
              <Route path="/solutions" element={<Solutions />} />
              <Route path="/contact" element={<ContactUs />} />
            </Routes>
          </main>
        </div>
      </Router>
  );
};

const HomePage = ({ phrases, prefixPhrases, currentPrefixIndex, animationActive, handleSceneClick }) => (
    <>
      <h1 className="we-create text-4xl font-bold text-gray-900 mb-8 md:mb-16">
        {prefixPhrases[currentPrefixIndex]} <FlipWords words={phrases}/>
      </h1>
      <div className="explore-highlight mt-8 md:mt-16">
        EXPLORE
      </div>
      <div className="explore-subtext mt-2">
        NEW HORIZONS OF XR
      </div>
      <div className="w-full flex items-center justify-center mt-16 md:mt-16">
        <FullPageCanvas animationActive={animationActive} handleClose={handleSceneClick}/>
      </div>
    </>
);

export default App;
