// components/ui/BentoGrid.js
import React from 'react';
import { cn } from "../../utils/cn";

export const BentoGrid = ({ className, children }) => {
    return (
        <div className={cn("bento-grid", className)}>
            {children}
        </div>
    );
};

export const BentoGridItem = ({
                                  className,
                                  title,
                                  description,
                                  icon,
                                  onClick,
                              }) => {
    return (
        <div className={cn("bento-grid-item", className)} onClick={onClick}>
            <div className="icon">{icon}</div>
            <div className="title">{title}</div>
            <div className="description">{description}</div>
        </div>
    );
};
