// components/ui/TextGenerateEffect.js
import React, { useEffect } from 'react';
import { motion, useAnimation } from 'framer-motion';
import { cn } from '../../utils/cn';

const TextGenerateEffect = ({ words, className }) => {
    const controls = useAnimation();
    const wordsArray = words.split(" ");

    useEffect(() => {
        controls.start((i) => ({
            opacity: 1,
            transition: {
                duration: 0.5,
                delay: i * 0.2,
            },
        }));
    }, [controls]);

    const renderWords = () => {
        return (
            <div>
                {wordsArray.map((word, index) => (
                    <motion.span
                        key={word + index}
                        custom={index}
                        initial={{ opacity: 0 }}
                        animate={controls}
                        className="dark:text-white text-black"
                    >
                        {word}{" "}
                    </motion.span>
                ))}
            </div>
        );
    };

    return (
        <div className={cn("font-bold", className)}>
            <div className="mt-4">
                <div className="dark:text-white text-black text-2xl leading-snug tracking-wide">
                    {renderWords()}
                </div>
            </div>
        </div>
    );
};

export default TextGenerateEffect;
