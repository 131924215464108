import React, { useEffect, useState } from 'react';
import './Loader.css';

const Loader = () => {
    const [loading, setLoading] = useState(true);
    const [progress, setProgress] = useState(0);

    useEffect(() => {
        const interval = setInterval(() => {
            setProgress(prev => {
                if (prev >= 99) {
                    clearInterval(interval);
                    setTimeout(() => {
                        setLoading(false);
                    }, 1000); // Allow time for fade-out animation
                    return 100;
                }
                return prev + 1;
            });
        }, 30); // Adjust the duration as needed to simulate loading time

        return () => clearInterval(interval);
    }, []);

    useEffect(() => {
        if (progress === 100) {
            document.body.style.pointerEvents = 'none'; // Prevent interaction
            setTimeout(() => {
                document.body.style.pointerEvents = 'auto'; // Allow interaction after fade out
            }, 1500); // Adjust this duration to match the fade out animation time
        }
    }, [progress]);

    return (
        <div className={`loader-container ${loading ? 'loading' : 'loaded'}`} style={{ backdropFilter: `blur(${9 - progress * 0.09}px)` }}>
            <div className="loader-texts">
                <div className="loader-title">ATSAE Technologies</div>
                <div className="loader-subtitle">We develop XR applications</div>
            </div>
            <div className="loader-bar-container">
                <div className="loader-bar" style={{ width: `${progress}%` }}></div>
            </div>
            <div className="loader-percentage">{progress}%</div>
        </div>
    );
};

export default Loader;
