// FullPageCanvas.js
import React, { Suspense } from 'react';
import { Canvas } from '@react-three/fiber';
import { ContactShadows } from '@react-three/drei';
import Scene from './Scene';
import { useStore } from '../store/useStore';
import Loader from './Loader'; // Import the loader

const FullPageCanvas = ({ animationActive, handleClose }) => {
    const { blurBackground } = useStore();

    return (
        <div className={`canvas-container ${blurBackground ? 'blur-background' : ''}`} style={{ position: 'fixed', top: 300, left: 0, width: '100vw', height: '100vh', zIndex: 100 }}>
            <Suspense fallback={<Loader />}>
                <Canvas
                    shadows
                    gl={{ antialias: true }}
                >
                    <ambientLight intensity={2} />
                    <spotLight intensity={1} angle={0.1} penumbra={1} position={[10, 15, 10]} castShadow />
                    <Scene animationActive={animationActive} handleClose={handleClose} />
                    <ContactShadows position={[0, -0.5, 0]} opacity={0.5} scale={12} blur={1.5} far={0.8} />
                </Canvas>
            </Suspense>
        </div>
    );
};

export default FullPageCanvas;
