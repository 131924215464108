// components/AboutUs.js
import React from 'react';
import { motion } from 'framer-motion';
import TextGenerateEffect from './ui/TextGenerateEffect';

const aboutData = [
    {
        title: 'Our Mission',
        description: 'Our mission is to push the boundaries of technology and creativity to provide our clients with the best possible solutions.',
        icon: '🚀',
    },
    {
        title: 'Our Vision',
        description: 'We envision a future where immersive technology is seamlessly integrated into everyday life, enhancing experiences and interactions.',
        icon: '🌟',
    },
    {
        title: 'Our Values',
        description: 'Innovation, excellence, and integrity are at the core of everything we do. We are committed to delivering outstanding results and building lasting relationships.',
        icon: '💎',
    },
];

const aboutText = "Welcome to ATSAE Technologies! We are dedicated to creating immersive experiences and innovative solutions using cutting-edge technology.";

const AboutUs = () => {
    return (
        <div className="about-us-container min-h-screen flex flex-col items-center justify-center text-center p-8">
            <motion.h1
                className="text-4xl font-bold mb-4"
                initial={{ opacity: 0, y: -50 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.6 }}
            >
                About Us
            </motion.h1>
            <TextGenerateEffect words={aboutText} className="text-lg mb-8" />
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
                {aboutData.map((item, index) => (
                    <motion.div
                        key={index}
                        className="about-card p-6 bg-white rounded-lg shadow-lg"
                        whileHover={{ scale: 1.05 }}
                        whileTap={{ scale: 0.95 }}
                        initial={{ opacity: 0, y: 50 }}
                        animate={{ opacity: 1, y: 0 }}
                        transition={{ duration: 0.6, delay: index * 0.1 }}
                    >
                        <div className="about-icon text-5xl mb-4">{item.icon}</div>
                        <h2 className="text-2xl font-bold mb-2">{item.title}</h2>
                        <p className="text-gray-700">{item.description}</p>
                    </motion.div>
                ))}
            </div>
        </div>
    );
};

export default AboutUs;
