import { useState, useEffect } from 'react';

const useScrollBackground = (backgroundImages) => {
    const [currentBackgroundIndex, setCurrentBackgroundIndex] = useState(0);

    useEffect(() => {
        const handleScroll = () => {
            const scrollY = window.scrollY;
            const windowHeight = window.innerHeight;
            const newBackgroundIndex = Math.floor(scrollY / windowHeight) % backgroundImages.length;
            setCurrentBackgroundIndex(newBackgroundIndex);
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [backgroundImages.length]);

    return backgroundImages[currentBackgroundIndex];
};

export default useScrollBackground;
