// src/components/ContactUs.js
import React from 'react';
import GlobeComponent from './GlobeComponent';
import './ContactUs.css';

const ContactUs = () => {
    return (
        <div className="relative h-screen w-screen overflow-hidden flex items-center justify-center">
            <div className="absolute top-0 left-0 w-full h-full z-0">
                <GlobeComponent />
            </div>
            <div className="relative bg-white bg-opacity-50 p-8 rounded-lg max-w-md text-center shadow-lg backdrop-blur-md z-10">
                <h1 className="text-2xl font-bold mb-4">Contact Us</h1>
                <p className="text-base mb-6">If you have any questions, feel free to reach out to us.</p>
                <input className="contact-input w-full p-3 mb-4 rounded-lg border-none" type="text" placeholder="Your Name" />
                <input className="contact-input w-full p-3 mb-4 rounded-lg border-none" type="email" placeholder="Your Email" />
                <textarea className="contact-input w-full p-3 mb-4 rounded-lg border-none" placeholder="Your Message"></textarea>
                <button className="contact-button w-full p-3 rounded-lg border-none bg-blue-500 text-white font-bold hover:bg-blue-600 transition">Send Message</button>
            </div>
        </div>
    );
};

export default ContactUs;
