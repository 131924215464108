// src/components/GlobeComponent.js
import React, { useRef, useEffect } from 'react';
import Globe from 'react-globe.gl';
import * as THREE from 'three';

const GlobeComponent = () => {
    const globeEl = useRef();

    // Define arcs data from specific cities to Chennai and from Chennai to specific cities
    const arcsData = [
        { startLat: 53.5461, startLng: -113.4938, endLat: 13.0827, endLng: 80.2707, color: ['red', 'orange'] }, // Edmonton to Chennai
        { startLat: 51.5074, startLng: -0.1278, endLat: 13.0827, endLng: 80.2707, color: ['blue', 'green'] }, // London to Chennai
        { startLat: -15.7801, startLng: -47.9292, endLat: 13.0827, endLng: 80.2707, color: ['green', 'white'] }, // Brazil to Chennai
        { startLat: 1.3521, startLng: 103.8198, endLat: 13.0827, endLng: 80.2707, color: ['yellow', 'blue'] }, // Singapore to Chennai
        { startLat: 40.7128, startLng: -74.0060, endLat: 13.0827, endLng: 80.2707, color: ['purple', 'red'] }, // New York to Chennai
        { startLat: 36.7783, startLng: -119.4179, endLat: 13.0827, endLng: 80.2707, color: ['pink', 'yellow'] }, // California to Chennai
        { startLat: 55.7558, startLng: 37.6173, endLat: 13.0827, endLng: 80.2707, color: ['orange', 'green'] }, // Moscow to Chennai
        { startLat: 13.0827, startLng: 80.2707, endLat: 53.5461, endLng: -113.4938, color: ['orange', 'red'] }, // Chennai to Edmonton
        { startLat: 13.0827, startLng: 80.2707, endLat: 51.5074, endLng: -0.1278, color: ['green', 'blue'] }, // Chennai to London
        { startLat: 13.0827, startLng: 80.2707, endLat: -15.7801, endLng: -47.9292, color: ['white', 'green'] }, // Chennai to Brazil
        { startLat: 13.0827, startLng: 80.2707, endLat: 1.3521, endLng: 103.8198, color: ['blue', 'yellow'] }, // Chennai to Singapore
        { startLat: 13.0827, startLng: 80.2707, endLat: 40.7128, endLng: -74.0060, color: ['red', 'purple'] }, // Chennai to New York
        { startLat: 13.0827, startLng: 80.2707, endLat: 36.7783, endLng: -119.4179, color: ['yellow', 'pink'] }, // Chennai to California
        { startLat: 13.0827, startLng: 80.2707, endLat: 55.7558, endLng: 37.6173, color: ['green', 'orange'] }  // Chennai to Moscow
    ];

    useEffect(() => {
        const globe = globeEl.current;
        if (globe && globe.controls) {
            globe.controls().autoRotate = true;
            globe.controls().autoRotateSpeed = 0.3;
            globe.pointOfView({ altitude: 2 });

            // Add clouds sphere
            const CLOUDS_IMG_URL = './assets/clouds.png';
            const CLOUDS_ALT = 0.004;
            const CLOUDS_ROTATION_SPEED = -0.006; // deg/frame

            new THREE.TextureLoader().load(CLOUDS_IMG_URL, cloudsTexture => {
                const clouds = new THREE.Mesh(
                    new THREE.SphereGeometry(globe.getGlobeRadius() * (1 + CLOUDS_ALT), 75, 75),
                    new THREE.MeshPhongMaterial({ map: cloudsTexture, transparent: true })
                );
                globe.scene().add(clouds);

                (function rotateClouds() {
                    clouds.rotation.y += CLOUDS_ROTATION_SPEED * Math.PI / 180;
                    requestAnimationFrame(rotateClouds);
                })();
            });
        }
    }, []);

    return (
        <Globe
            ref={globeEl}
            globeImageUrl="//unpkg.com/three-globe/example/img/earth-blue-marble.jpg"
            bumpImageUrl="//unpkg.com/three-globe/example/img/earth-topology.png"
            arcsData={arcsData}
            arcColor={(d) => d.color}
            arcDashLength={() => Math.random()}
            arcDashGap={() => Math.random()}
            arcDashAnimateTime={() => Math.random() * 4000 + 500}
            arcAltitude={(d) => 0.3}  // Increased arc altitude
            arcStroke={(d) => 0.5}
        />
    );
};

export default GlobeComponent;
