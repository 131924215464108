import React, { useState, useEffect, useRef } from 'react';
import { useThree, useFrame } from '@react-three/fiber';
import { Vector2, Raycaster } from 'three';
import VRHeadset from './VRHeadset';
import MobilePhone from './MobilePhone';
import VisionPro from './VisionPro';
import { useStore } from '../store/useStore';

const Scene = ({ animationActive, handleClose }) => {
    const [mobilePhoneAnimationActive, setMobilePhoneAnimationActive] = useState(false);
    const [vrHeadsetAnimationActive, setVRHeadsetAnimationActive] = useState(false);
    const [visionProAnimationActive, setVisionProAnimationActive] = useState(false);

    const { camera, scene } = useThree();
    const raycaster = useRef(new Raycaster());
    const mouse = useRef(new Vector2());

    const setActiveObject = useStore(state => state.setActiveObject);
    const activeObject = useStore(state => state.activeObject);

    const handleMobilePhoneClose = () => {
        setMobilePhoneAnimationActive(false);
    };

    const handleVRHeadsetClose = () => {
        setVRHeadsetAnimationActive(false);
    };

    const handleVisionProClose = () => {
        setVisionProAnimationActive(false);
    };

    useEffect(() => {
        const handleMouseMove = (event) => {
            mouse.current.x = (event.clientX / window.innerWidth) * 2 - 1;
            mouse.current.y = -(event.clientY / window.innerHeight) * 2 + 1;
        };

        const handleClick = () => {
            raycaster.current.setFromCamera(mouse.current, camera);
            const intersects = raycaster.current.intersectObjects(scene.children, true);

            if (intersects.length > 0) {
                const firstIntersect = intersects[0].object;
                // Set the active object based on the intersected object
                if (firstIntersect.userData && firstIntersect.userData.type) {
                    setActiveObject(firstIntersect.userData.type);
                }
            } else {
                // Deselect any active object if clicked on empty space
                setActiveObject(null);
            }
        };

        window.addEventListener('mousemove', handleMouseMove);
        window.addEventListener('click', handleClick);

        return () => {
            window.removeEventListener('mousemove', handleMouseMove);
            window.removeEventListener('click', handleClick);
        };
    }, [camera, scene, setActiveObject]);

    useFrame(() => {
        raycaster.current.setFromCamera(mouse.current, camera);
        const intersects = raycaster.current.intersectObjects(scene.children, true);

        if (intersects.length > 0) {
            const firstIntersect = intersects[0].object;
            // Handle hover or other interactions if needed
        }
    });

    return (
        <>
            <VRHeadset
                animationActive={vrHeadsetAnimationActive}
                handleClose={handleVRHeadsetClose}
            />
            <MobilePhone
                animationActive={mobilePhoneAnimationActive}
                handleClose={handleMobilePhoneClose}
            />
            <VisionPro
                animationActive={visionProAnimationActive}
                handleClose={handleVisionProClose}
            />
        </>
    );
};

export default Scene;
