// components/Solutions.js
import React, { useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { Canvas } from '@react-three/fiber';
import { OrbitControls, Box } from '@react-three/drei';
import { BentoGrid, BentoGridItem } from './ui/BentoGrid';

const solutionsData = [
    {
        title: 'Medical Training Simulations',
        description: 'We provide VR simulations for medical training, allowing healthcare professionals to practice procedures in a risk-free environment.',
        icon: '🩺',
        size: 'large',
        type: 'video',
        media: ''
    },
    {
        title: 'Educational VR Experiences',
        description: 'Our educational VR experiences make learning interactive and engaging, helping students grasp complex concepts through immersive environments.',
        icon: '📚',
        size: 'small',
        type: 'image',
        media: ''
    },
    {
        title: 'Industrial AR Maintenance',
        description: 'Our AR maintenance solutions assist technicians in performing complex tasks by overlaying digital information on physical equipment.',
        icon: '🛠️',
        size: 'small',
        type: 'image',
        media: ''
    },
    {
        title: 'Interactive Walls',
        description: 'Our interactive walls transform spaces into engaging environments, perfect for exhibitions, museums, and retail.',
        icon: '🖼️',
        size: 'medium',
        type: 'threejs'
    },
    {
        title: 'Fire Safety Training',
        description: 'We provide fire safety training through immersive VR simulations, ensuring that your team is prepared for emergencies.',
        icon: '🔥',
        size: 'small',
        type: 'video',
        media: ''
    },
    {
        title: 'Virtual Simulators',
        description: 'Our virtual simulators provide realistic training environments for various industries, enhancing skill development and safety.',
        icon: '🕹️',
        size: 'large',
        type: 'threejs'
    },
    {
        title: 'Assembly Training',
        description: 'We offer assembly training solutions using AR technology, improving efficiency and accuracy in manufacturing processes.',
        icon: '🔧',
        size: 'medium',
        type: 'image',
        media: ''
    },
    {
        title: 'Product Visualization',
        description: 'Our product visualization solutions use VR and AR to provide detailed, interactive views of your products, enhancing customer experience.',
        icon: '📦',
        size: 'small',
        type: 'video',
        media: ''
    },
];

const Loader = () => (
    <div className="loader"></div>
);

const Solutions = () => {
    const [selectedSolution, setSelectedSolution] = useState(null);

    const handleSolutionClick = (solution) => {
        setSelectedSolution(solution);
    };

    const handleClose = () => {
        setSelectedSolution(null);
    };

    return (
        <div className="solutions-container min-h-screen flex flex-col items-center justify-center text-center p-8">
            <motion.h1
                className="text-4xl font-bold mb-4"
                initial={{ opacity: 0, y: -50 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.6 }}
            >
                Our Solutions
            </motion.h1>
            <motion.p
                className="text-lg mb-8"
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ duration: 0.6, delay: 0.3 }}
            >
                Our innovative solutions are designed to meet the unique needs of your business. We combine cutting-edge technology with creative thinking to deliver results that exceed expectations.
            </motion.p>
            <BentoGrid>
                {solutionsData.map((solution, index) => (
                    <BentoGridItem
                        key={index}
                        className={solution.size}
                        title={solution.title}
                        description={solution.description}
                        icon={<div className="solution-icon text-5xl">{solution.icon}</div>}
                        onClick={() => handleSolutionClick(solution)}
                    />
                ))}
            </BentoGrid>
            <AnimatePresence>
                {selectedSolution && (
                    <motion.div
                        className="overlay"
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        exit={{ opacity: 0 }}
                        onClick={handleClose}
                    >
                        <motion.div
                            className="detailed-view"
                            initial={{ scale: 0.8, opacity: 0 }}
                            animate={{ scale: 1, opacity: 1 }}
                            exit={{ scale: 0.8, opacity: 0 }}
                            onClick={(e) => e.stopPropagation()}
                        >
                            <button className="close-button" onClick={handleClose}>✖</button>
                            <h2 className="text-2xl font-bold mb-4">{selectedSolution.title}</h2>
                            <p className="text-lg mb-4">{selectedSolution.description}</p>
                            {selectedSolution.type === 'image' && (
                                selectedSolution.media ?
                                    <img src={selectedSolution.media} alt={selectedSolution.title} className="w-full rounded-lg" /> :
                                    <div className="w-full flex justify-center items-center"><Loader /></div>
                            )}
                            {selectedSolution.type === 'video' && (
                                selectedSolution.media ?
                                    <video controls className="w-full rounded-lg">
                                        <source src={selectedSolution.media} type="video/mp4" />
                                        Your browser does not support the video tag.
                                    </video> :
                                    <div className="w-full flex justify-center items-center"><Loader /></div>
                            )}
                            {selectedSolution.type === 'threejs' && (
                                <Canvas style={{ height: '300px', borderRadius: '10px' }}>
                                    <ambientLight />
                                    <OrbitControls />
                                    <Box args={[1, 1, 1]}>
                                        <meshStandardMaterial attach="material" color="orange" />
                                    </Box>
                                </Canvas>
                            )}
                        </motion.div>
                    </motion.div>
                )}
            </AnimatePresence>
        </div>
    );
};

export default Solutions;
