import React, { useRef, useEffect, useState } from 'react';
import { useFrame, useLoader, useThree } from '@react-three/fiber';
import { useSpring, a } from '@react-spring/three';
import { Group, Euler, WebGLRenderTarget, MeshBasicMaterial, PerspectiveCamera } from 'three';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';
import { DRACOLoader } from 'three/examples/jsm/loaders/DRACOLoader';
import { useStore } from '../store/useStore'; // Import the Zustand store

const createGLTFLoader = () => {
    const loader = new GLTFLoader();
    const dracoLoader = new DRACOLoader();
    dracoLoader.setDecoderPath('/draco-gltf/'); // Ensure this path is correct
    loader.setDRACOLoader(dracoLoader);
    return loader;
};

const MobilePhone = ({ scrollY, animationActive, handleClose }) => {
    const groupRef = useRef(null);
    const screenRef = useRef(null);
    const cameraRef = useRef(null);
    const [originalMaterial, setOriginalMaterial] = useState(null); // State to store the original material
    const [isMoved, setIsMoved] = useState(false); // State to track position
    const [renderTextureApplied, setRenderTextureApplied] = useState(false);

    const { gl, scene } = useThree();

    // Create a render target
    const renderTarget = new WebGLRenderTarget(512, 512);

    // Load the GLTF model using the custom loader
    const gltf = useLoader(GLTFLoader, '/assets/iphone_15_pro.glb', loader => {
        const customLoader = createGLTFLoader();
        loader.setDRACOLoader(customLoader.dracoLoader);
    });

    // Get state and setState from Zustand store
    const { mobilePhonePosition, mobilePhoneRotation, resetAll, setMobilePhoneState, setActiveObject, activeObject } = useStore();

    useEffect(() => {
        if (gltf && groupRef.current) {
            groupRef.current.position.set(...mobilePhonePosition);  // Set initial position
            groupRef.current.rotation.set(...mobilePhoneRotation);  // Set initial rotation
            groupRef.current.scale.set(5, 5, 5);  // Set initial scale
            groupRef.current.add(gltf.scene);

            // Find the "Screen" node and store its original material
            const screenNode = gltf.scene.getObjectByName('Screen');
            if (screenNode) {
                setOriginalMaterial(screenNode.material);
                screenRef.current = screenNode;
            }
        }
    }, [gltf, mobilePhonePosition, mobilePhoneRotation]);

    const [springProps, api] = useSpring(() => ({
        position: mobilePhonePosition,
        rotation: mobilePhoneRotation,
        scale: [5, 5, 5],
        config: { mass: 1, tension: 120, friction: 60 },
        onChange: ({ value }) => {
            if (screenRef.current && renderTarget && value.rotation[1] >= Math.PI / 2 && value.rotation[1] <= 3 * Math.PI / 2) {
                // Change the material of the "Screen" node to use the render texture
                if (!renderTextureApplied) {
                    const screenMaterial = new MeshBasicMaterial({ map: renderTarget.texture });
                    screenRef.current.material = screenMaterial;
                    setRenderTextureApplied(true);
                }
            } else if (screenRef.current && renderTextureApplied && (value.rotation[1] < Math.PI / 2 || value.rotation[1] > 3 * Math.PI / 2)) {
                // Revert the material of the "Screen" node to the original material
                screenRef.current.material = originalMaterial;
                setRenderTextureApplied(false);
            }
        }
    }));

    useEffect(() => {
        api.start({
            position: animationActive ? [0.04, 0.22, 4.1] : mobilePhonePosition,
            rotation: animationActive ? [0, 2 * Math.PI, 0] : mobilePhoneRotation,
            scale: [5, 5, 5],
            config: { mass: 1, tension: 120, friction: 60 }
        });
    }, [animationActive, mobilePhonePosition, mobilePhoneRotation]);

    useFrame(() => {
        if (groupRef.current && !animationActive && activeObject !== 'mobilePhone') {
            const elapsedTime = performance.now() / 1000;
            groupRef.current.position.y = Math.sin(elapsedTime) * 0.1; // Floating effect
        }
        if (groupRef.current && (animationActive || activeObject === 'mobilePhone')) {
            groupRef.current.position.y = 0;
        }

        // Render the scene from the perspective of the camera into the render target if active
        if (cameraRef.current && screenRef.current && activeObject === 'mobilePhone') {
            gl.setRenderTarget(renderTarget);
            gl.render(scene, cameraRef.current);
            gl.setRenderTarget(null);
        }
    });

    const handleMobilePhoneClick = () => {
        if (activeObject !== 'mobilePhone') {
            setActiveObject('mobilePhone');  // Set the active object to 'mobilePhone'
            setIsMoved(true);
            const newPosition = [0.04, 0.22, 4.1];
            const newRotation = [0, 2 * Math.PI, 0];

            setMobilePhoneState(newPosition, newRotation); // Update the Zustand store
            api.start({
                position: newPosition,
                rotation: newRotation,
                scale: [5, 5, 5],
                onRest: () => {
                    console.log('Mobile Phone Model Clicked!');
                },
                config: { mass: 1, tension: 120, friction: 60 }
            });
        } else {
            setActiveObject(null);  // Reset the active object
            setIsMoved(false);
            const initialPosition = [-2.56, 0, 0];
            const initialRotation = [0, 0.2827433388230814, 0];

            setMobilePhoneState(initialPosition, initialRotation); // Update the Zustand store
            api.start({
                position: initialPosition,
                rotation: initialRotation,
                scale: [5, 5, 5],
                onRest: () => {
                    console.log('Mobile Phone Model Reset!');
                },
                config: { mass: 1, tension: 120, friction: 60 }
            });
        }
    };

    const eulerRotation = new Euler();
    return (
        <>
            <a.group
                ref={groupRef}
                position={springProps.position}
                rotation={springProps.rotation.to((x, y, z) => eulerRotation.set(x, y, z))}
                scale={springProps.scale}
                castShadow
                receiveShadow
                onClick={handleMobilePhoneClick}
            >
                <primitive object={gltf.scene} castShadow />
            </a.group>
            {/* Add a camera inside the mobile phone */}
            {activeObject === 'mobilePhone' && (
                <perspectiveCamera
                    ref={cameraRef}
                    position={[0, 0.5, 0]} // Adjust the position as needed
                    fov={50}
                    aspect={512 / 512}
                    near={0.1}
                    far={1000}
                />
            )}
        </>
    );
};

export default MobilePhone;
