import React, { useRef, useEffect, useState } from 'react';
import { useFrame, useLoader, useThree } from '@react-three/fiber';
import { useSpring, a } from '@react-spring/three';
import { Group, Object3D, Euler, VideoTexture, MeshStandardMaterial, DoubleSide } from 'three';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';
import { DRACOLoader } from 'three/examples/jsm/loaders/DRACOLoader';
import { Html, OrbitControls } from '@react-three/drei';
import { EffectComposer, Vignette } from '@react-three/postprocessing';
import { useStore } from '../store/useStore';

const createGLTFLoader = () => {
    const loader = new GLTFLoader();
    const dracoLoader = new DRACOLoader();
    dracoLoader.setDecoderPath('/draco-gltf/'); // Ensure this path is correct
    loader.setDRACOLoader(dracoLoader);
    return loader;
};

const VRHeadset = ({ ScrollY, animationActive, handleClose }) => {
    const groupRef = useRef(null);
    const headsetOuterLowRef = useRef(null);
    const videoRef = useRef(null);
    const [videoTexture, setVideoTexture] = useState(null);
    const [showVideo, setShowVideo] = useState(false);
    const [vignetteEffect, setVignetteEffect] = useState({ offset: 0, darkness: 0 });
    const [atDestination, setAtDestination] = useState(false);
    const [showSphere, setShowSphere] = useState(false);
    const [headsetVisible, setHeadsetVisible] = useState(true);
    const [is360Video, setIs360Video] = useState(false);
    const [isFading, setIsFading] = useState(false); // Initialize fade state as false

    // Get state and setState from Zustand store
    const { headsetPosition, headsetRotation, setHeadsetState, setActiveObject, activeObject } = useStore();

    const initialPosition = [0, 0, 0];
    const destinationPosition = [0, -0.22, 5.1];
    const initialRotation = [0, 0, 0];
    const destinationRotation = [0, Math.PI, 0];
    const scale = [5, 5, 5];

    // Load the GLTF model using the custom loader
    const gltf = useLoader(GLTFLoader, '/assets/vr-headset-model.glb', loader => {
        const customLoader = createGLTFLoader();
        loader.setDRACOLoader(customLoader.dracoLoader);
    });

    const { gl } = useThree();
    const closeButtonRef = useRef(null);

    useEffect(() => {
        if (closeButtonRef.current) {
            gl.domElement.parentNode.appendChild(closeButtonRef.current);
            return () => {
                gl.domElement.parentNode.removeChild(closeButtonRef.current);
            };
        }
    }, [gl]);

    useEffect(() => {
        if (gltf && groupRef.current) {
            groupRef.current.position.set(...initialPosition);  // Set initial position
            groupRef.current.rotation.set(...initialRotation);  // Set initial rotation
            groupRef.current.scale.set(...scale);  // Set initial scale
            groupRef.current.add(gltf.scene);
            headsetOuterLowRef.current = gltf.scene.getObjectByName('headset_outer_low');
        }
    }, [gltf]);

    useEffect(() => {
        if (videoRef.current) {
            const texture = new VideoTexture(videoRef.current);
            setVideoTexture(texture);

            // Check if the video is a 360 video
            if (videoRef.current.src.includes('_360')) {
                setIs360Video(true);
            } else {
                setIs360Video(false);
            }
        }
    }, [videoRef.current]);

    const [springProps, api] = useSpring(() => ({
        position: headsetPosition,
        rotation: headsetRotation,
        scale: scale,
        config: { mass: 1, tension: 120, friction: 60 },
        onChange: (result) => {
            const zPosition = result.value.position[2];
            const progress = zPosition / 5.1;  // Assuming the target z position is 5.1
            if (zPosition > 4.5 && zPosition <= 5.1) {
                const effectProgress = (zPosition - 4.5) / (5.1 - 4.5);  // Normalize between 0 and 1
                setVignetteEffect({
                    offset: 0.1 * effectProgress,
                    darkness: 1.1 * effectProgress
                });
            } else {
                setVignetteEffect({ offset: 0, darkness: 0 });
            }
        },
        onRest: () => {
            const zPosition = groupRef.current.position.z;
            if (zPosition >= 4.5 && zPosition <= 5.1) {
                setShowSphere(true);
                setHeadsetVisible(false); // Hide the headset
                setTimeout(() => {
                    setIsFading(true); // Start fade-in effect
                    setTimeout(() => {
                        if (videoRef.current) {
                            videoRef.current.play();
                        }
                        setIsFading(false); // Fade out
                    }, 1000); // Delay for fade-in effect
                }, 0); // No delay, start fade immediately
            } else {
                setShowSphere(false);
                setHeadsetVisible(true); // Show the headset
                if (videoRef.current) {
                    videoRef.current.pause();
                    videoRef.current.currentTime = 0;
                }
            }
        }
    }));

    useEffect(() => {
        if (animationActive) {
            setShowVideo(true);
            if (videoRef.current) {
                videoRef.current.play();
            }
        } else {
            setShowVideo(false);
            if (videoRef.current) {
                videoRef.current.pause();
                videoRef.current.currentTime = 0;
            }
        }
    }, [animationActive]);

    useFrame(() => {
        if (groupRef.current) {
            const elapsedTime = performance.now() / 1000;
            if (!atDestination) {
                groupRef.current.position.y = Math.sin(elapsedTime) * 0.1; // Floating effect
            }
        }
    });

    const handleVideoClose = () => {
        if (videoRef.current) {
            videoRef.current.pause();
            videoRef.current.currentTime = 0;
        }
        setShowSphere(false);
        setAtDestination(false);
        setHeadsetVisible(true); // Show the headset when the video is closed
        api.start({
            position: initialPosition,
            rotation: initialRotation,
            scale: scale,
            config: { mass: 1, tension: 120, friction: 60 },
            onRest: () => setIsFading(false), // Reset the fade state onRest
        });
        handleClose();
        setShowVideo(false);
    };

    const handleVRHeadsetClick = () => {
        if (activeObject !== 'vrHeadset') {
            setActiveObject('vrHeadset');  // Set the active object to 'vrHeadset'
            setAtDestination(true);
            const newPosition = destinationPosition;
            const newRotation = destinationRotation;

            setHeadsetState(newPosition, newRotation); // Update the Zustand store
            api.start({
                position: newPosition,
                rotation: newRotation,
                scale: scale,
                config: { mass: 1, tension: 120, friction: 60 }
            });
        } else {
            setActiveObject(null);  // Reset the active object
            setAtDestination(false);
            const initialPosition = [0, 0, 0];
            const initialRotation = [0, -0.2827433388230814, 0];

            setHeadsetState(initialPosition, initialRotation); // Update the Zustand store
            api.start({
                position: initialPosition,
                rotation: initialRotation,
                scale: scale,
                config: { mass: 1, tension: 120, friction: 60 }
            });
        }
    };

    return (
        <>
            <ambientLight intensity={0.5}/>
            <directionalLight
                castShadow
                position={[5, 5, 5]}
                intensity={1}
                shadow-mapSize-width={1024}
                shadow-mapSize-height={1024}
                shadow-camera-far={50}
                shadow-camera-left={-10}
                shadow-camera-right={10}
                shadow-camera-top={10}
                shadow-camera-bottom={-10}
            />
            {headsetVisible && (
                <a.group
                    ref={groupRef}
                    position={springProps.position}
                    rotation={springProps.rotation.to((x, y, z) => new Euler(x, y, z))}
                    scale={springProps.scale}
                    castShadow
                    receiveShadow
                    onClick={handleVRHeadsetClick}  // Separate click handler for the VR Headset
                >
                    <primitive object={gltf.scene} castShadow />
                </a.group>
            )}
            {showSphere && videoTexture && (
                is360Video ? (
                    <mesh renderOrder={101} position={[0, 0, 0]}>
                        <sphereGeometry args={[50, 60, 40]} />
                        <meshStandardMaterial map={videoTexture} side={DoubleSide} />
                        <OrbitControls enableZoom={false} enablePan={false} rotateSpeed={-0.25} />
                    </mesh>
                ) : (
                    <mesh renderOrder={101} position={[0, 0, 0]}>
                        <planeGeometry args={[16, 9]} />
                        <meshStandardMaterial map={videoTexture} side={DoubleSide} />
                        <OrbitControls enableZoom={true} enablePan={true} />
                    </mesh>
                )
            )}

            <EffectComposer>
                <Vignette eskil={false} offset={vignetteEffect.offset} darkness={vignetteEffect.darkness}/>
            </EffectComposer>
            <Html fullscreen>
                <video
                    id="video"
                    ref={videoRef}
                    preload="none"
                    style={{ display: 'none' }}
                    loop
                    playsInline
                    muted
                >
                    <source src="/assets/video.mp4" type="video/mp4" />
                    Your browser does not support the video tag.
                </video>
            </Html>
            {isFading && (
                <Html fullscreen>
                    <div
                        style={{
                            position: 'absolute',
                            top: 0,
                            left: 0,
                            width: '100%',
                            height: '100%',
                            backgroundColor: 'black',
                            opacity: isFading ? 1 : 0,
                            transition: 'opacity 1s ease-in-out',
                            pointerEvents: 'none', // Prevent interaction
                            zIndex: 200 // Ensure it's above other elements
                        }}
                    />
                </Html>
            )}
        </>
    );
};

export default VRHeadset;
