import React, { useRef, useEffect, useState } from 'react';
import { useFrame, useLoader, useThree } from '@react-three/fiber';
import { useSpring, a } from '@react-spring/three';
import { Group, Euler, WebGLRenderTarget, MeshBasicMaterial, PerspectiveCamera } from 'three';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';
import { DRACOLoader } from 'three/examples/jsm/loaders/DRACOLoader';
import { useStore } from '../store/useStore'; // Import the Zustand store

const createGLTFLoader = () => {
    const loader = new GLTFLoader();
    const dracoLoader = new DRACOLoader();
    dracoLoader.setDecoderPath('/draco-gltf/'); // Ensure this path is correct
    loader.setDRACOLoader(dracoLoader);
    return loader;
};

const VisionPro = ({ scrollY, animationActive, handleClose }) => {
    const groupRef = useRef(null);
    const screenRef = useRef(null);
    const cameraRef = useRef(null);
    const [originalMaterial, setOriginalMaterial] = useState(null); // State to store the original material
    const [renderTextureApplied, setRenderTextureApplied] = useState(false);

    const { gl, scene } = useThree();

    // Create a render target
    const renderTarget = new WebGLRenderTarget(512, 512);

    // Load the GLTF model using the custom loader
    const gltf = useLoader(GLTFLoader, '/assets/apple_vision_pro.glb', loader => {
        const customLoader = createGLTFLoader();
        loader.setDRACOLoader(customLoader.dracoLoader);
    });

    // Get state and setState from Zustand store
    const { visionProPosition, visionProRotation, resetAll, setVisionProState, setActiveObject, activeObject } = useStore();

    useEffect(() => {
        if (gltf && groupRef.current) {
            groupRef.current.position.set(...visionProPosition);  // Set initial position
            groupRef.current.rotation.set(...visionProRotation);  // Set initial rotation
            groupRef.current.scale.set(1.75, 1.75, 1.75);  // Set initial scale (adjust the scale as needed)
            groupRef.current.add(gltf.scene);

            // Find the "Screen" node and store its original material
            const screenNode = gltf.scene.getObjectByName('Screen');
            if (screenNode) {
                setOriginalMaterial(screenNode.material);
                screenRef.current = screenNode;
            }
        }
    }, [gltf, visionProPosition, visionProRotation]);

    const [springProps, api] = useSpring(() => ({
        position: visionProPosition,
        rotation: visionProRotation,
        scale: [1.75, 1.75, 1.75], // Adjust the scale as needed
        config: { mass: 1, tension: 120, friction: 60 },
        onChange: ({ value }) => {
            if (screenRef.current && renderTarget && value.rotation[1] >= Math.PI / 2 && value.rotation[1] <= 3 * Math.PI / 2) {
                // Change the material of the "Screen" node to use the render texture
                if (!renderTextureApplied) {
                    const screenMaterial = new MeshBasicMaterial({ map: renderTarget.texture });
                    screenRef.current.material = screenMaterial;
                    setRenderTextureApplied(true);
                }
            } else if (screenRef.current && originalMaterial && renderTextureApplied && (value.rotation[1] < Math.PI / 2 || value.rotation[1] > 3 * Math.PI / 2)) {
                // Revert the material of the "Screen" node to the original material
                screenRef.current.material = originalMaterial;
                setRenderTextureApplied(false);
            }
        }
    }));

    useEffect(() => {
        api.start({
            position: animationActive ? [0.04, 0.22, 4.1] : visionProPosition,
            rotation: animationActive ? [0, 2 * Math.PI, 0] : visionProRotation,
            scale: [1.75, 1.75, 1.75], // Adjust the scale as needed
            config: { mass: 1, tension: 120, friction: 60 }
        });
    }, [animationActive, visionProPosition, visionProRotation]);

    useFrame(() => {
        if (groupRef.current && !animationActive && activeObject !== 'visionPro') {
            const elapsedTime = performance.now() / 1000;
            groupRef.current.position.y = Math.sin(elapsedTime) * 0.1; // Floating effect
        }
        if (groupRef.current && (animationActive || activeObject === 'visionPro')) {
            groupRef.current.position.y = 0;
        }

        // Render the scene from the perspective of the camera into the render target if active
        if (cameraRef.current && screenRef.current && activeObject === 'visionPro') {
            gl.setRenderTarget(renderTarget);
            gl.render(scene, cameraRef.current);
            gl.setRenderTarget(null);
        }
    });

    const handleVisionProClick = () => {
        if (activeObject !== 'visionPro') {
            setActiveObject('visionPro');  // Set the active object to 'visionPro'
            const newPosition = [0.04, 0.22, 5.2];
            const newRotation = [0, 2 * Math.PI / 4, 0];

            setVisionProState(newPosition, newRotation); // Update the Zustand store
            api.start({
                position: newPosition,
                rotation: newRotation,
                scale: [2, 2, 2], // Adjust the scale as needed
                onRest: () => {
                    console.log('Vision Pro Model Clicked!');
                },
                config: { mass: 1, tension: 120, friction: 60 }
            });
        } else {
            setActiveObject(null);  // Reset the active object
            const initialPosition = [5, 0, 0];
            const initialRotation = [0, -Math.PI / 2, 0]; // Rotate to face -90 degrees

            setVisionProState(initialPosition, initialRotation); // Update the Zustand store
            api.start({
                position: initialPosition,
                rotation: initialRotation,
                scale: [2, 2, 2], // Adjust the scale as needed
                onRest: () => {
                    console.log('Vision Pro Model Reset!');
                },
                config: { mass: 1, tension: 120, friction: 60 }
            });
        }
    };

    const eulerRotation = new Euler();
    return (
        <>
            <a.group
                ref={groupRef}
                position={springProps.position}
                rotation={springProps.rotation.to((x, y, z) => eulerRotation.set(x, y, z))}
                scale={springProps.scale}
                castShadow
                receiveShadow
                onClick={handleVisionProClick}
            >
                <primitive object={gltf.scene} castShadow />
            </a.group>
            {/* Add a camera inside the VisionPro */}
            {activeObject === 'visionPro' && (
                <perspectiveCamera
                    ref={cameraRef}
                    position={[0, 0.5, 0]} // Adjust the position as needed
                    fov={50}
                    aspect={512 / 512}
                    near={0.1}
                    far={1000}
                />
            )}
        </>
    );
};

export default VisionPro;
