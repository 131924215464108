import create from 'zustand';

export const useStore = create((set, get) => ({
    mobilePhonePosition: [-2.56, 0, 0],
    mobilePhoneRotation: [0, 0.2827433388230814, 0],
    headsetPosition: [0, 0, 0],
    headsetRotation: [0, 0, 0],
    visionProPosition: [2.56, 0, 0], // Initial position for VisionPro
    visionProRotation: [0, -Math.PI/2, 0], // Initial rotation for VisionPro
    activeObject: null,
    resetAll: () => set({
        mobilePhonePosition: [-2.56, 0, 0],
        mobilePhoneRotation: [0, 0.2827433388230814, 0],
        headsetPosition: [0, 0, 0],
        headsetRotation: [0, 0, 0],
        visionProPosition: [2.56, 0, 0], // Reset VisionPro position
        visionProRotation: [0, -Math.PI/2, 0], // Reset VisionPro rotation
        activeObject: null,
    }),
    setActiveObject: (objectName) => {
        const { activeObject, setMobilePhoneState, setHeadsetState, setVisionProState } = get();

        if (activeObject && activeObject !== objectName) {
            // Reset the currently active object to its initial state
            if (activeObject === 'mobilePhone') {
                setMobilePhoneState([-2.56, 0, 0], [0, 0.2827433388230814, 0]);
            } else if (activeObject === 'vrHeadset') {
                setHeadsetState([0, 0, 0], [0, 0, 0]);
            } else if (activeObject === 'visionPro') {
                setVisionProState([2.56, 0, 0], [0, -Math.PI/2, 0]);
            }
        }

        set({
            activeObject: objectName,
        });
    },
    setMobilePhoneState: (position, rotation) => set({
        mobilePhonePosition: position,
        mobilePhoneRotation: rotation
    }),
    setHeadsetState: (position, rotation) => set({
        headsetPosition: position,
        headsetRotation: rotation
    }),
    setVisionProState: (position, rotation) => set({
        visionProPosition: position,
        visionProRotation: rotation
    }),
}));
